let overlayHideBoxCallback:((() => void) | null) = null;

import { getFirstParentSection } from "../../_helper";
import { showOverlay, hideOverlay } from "../../overlay/overlay";
import { overlayCloseHandlers } from "../../overlay/overlay";
import { showEmbeddingSections } from "../embedding/embedding";
import { hideElementContentHandler } from "../../_helper";

import "./box.css";
import "./box-yellow.css";
import "./box-teal.css";
import "./box-red.css";
import "./box-reading-text.css";
import "./box-magenta.css";
import "./box-invalid.css";
import "./box-green.css";
import "./box-gray.css";
import "./box-blue.css";
import "./box-orange.css";

const initBoxes = () => {
	document.querySelectorAll<HTMLElement>('section[content-type="box"] > inner-content > details').forEach(boxDetails => {
		boxDetails.classList.add("box-wrap");
		const boxSection = getFirstParentSection(boxDetails);
		const boxHeader  = boxDetails.querySelector<HTMLElement>("summary");
		const boxContent = boxDetails.querySelector<HTMLElement>("box-content");
		if((!boxDetails) || (!boxSection) || (!boxHeader) || (!boxContent)){
			return;
		}
		let boxVisible = false;
		let classRemoverTimer:ReturnType<typeof setTimeout> | null = null;

		const calcHeights = () => {
			if(boxVisible){
				const viewportHeight = window.innerHeight|0;
				const headerHeight   = boxHeader.clientHeight|0;
				boxContent.style.maxHeight = (viewportHeight - headerHeight - 80) + "px"; // 80 == padding
			}else{
				boxContent.style.maxHeight = "";
			}
		};

		const showBox = () => {
			boxVisible = true;
			const rect = boxHeader.getBoundingClientRect();
			if(!boxDetails.parentElement){return;}
			boxDetails.parentElement.style.height = ((rect.height|0)+8) + "px"; // Set a fixed height for the parent element, because as soon as we set position:fixed it will loose its height and would lead to content jumping aronud
			const oldTop = (rect.top|0);
			const newTop = window.innerWidth > 800 ? 32 : 16;
			const diff = oldTop - newTop;
			boxDetails.style.top = `${newTop}px`;
			boxDetails.style.transform = `translateY(${diff}px)`;
			boxDetails.classList.add("active");
			boxDetails.getBoundingClientRect(); // Sync CSS <-> JS
			boxDetails.style.transition = `transform 250ms`;
			window.requestAnimationFrame(() => {
				boxDetails.style.transform = `translateY(0)`;
			});
			showEmbeddingSections(boxContent);

			showOverlay(boxSection);
			overlayHideBoxCallback = hideBox;
			if(classRemoverTimer){
				window.clearTimeout(classRemoverTimer);
				classRemoverTimer = null;
			}
			calcHeights();
		};

		const hideBox = () => {
			if(!boxDetails.parentElement){return;}
			boxDetails.classList.add("closing");
			boxVisible = false;
			overlayHideBoxCallback = null;
			if(classRemoverTimer === null){
				classRemoverTimer = setTimeout(() => {
					boxDetails.classList.remove("closing");
					boxDetails.classList.remove("active");
					classRemoverTimer = null;
					boxDetails.setAttribute("style","");
					if(!boxDetails.parentElement){return;}
					boxDetails.parentElement.style.height = "";
				},0);
			}
			hideElementContentHandler(boxDetails);
			const prect = boxDetails.parentElement.getBoundingClientRect();
			const newTop = window.innerWidth > 800 ? 32 : 16;
			boxDetails.style.transform = `translateY(${(prect.top - newTop)|0}px)`;
			calcHeights();
		};

		boxHeader?.addEventListener("click", e => {
			e.preventDefault();
			boxDetails.setAttribute("open", "");
			boxDetails.offsetTop; // Sync CSS <-> JS
			if(boxVisible){
				hideOverlay();
			}else{
				showBox();
			}
		});
	});

	const hideCurBox = () => {
		if(!overlayHideBoxCallback){return;}
		overlayHideBoxCallback();
	};
	overlayCloseHandlers.push(hideCurBox);
};
setTimeout(initBoxes, 0);
